import React, { useRef, useState } from 'react';
import useOnClickOutside from '../hooks/useClickOutside';

const ContactButton = ({ className, lgJustify = 'center', text = false }) => {
  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef();
  const fullButtonClassName = 'btn btn--red btn-s ' + className;
  const fullContainerClassName =
    'relative flex justify-center items-center w-full lg:justify-' + lgJustify;

  useOnClickOutside(ref, () => setShowPopup(false));

  return (
    <div className={fullContainerClassName} ref={ref}>
      {showPopup && (
        <div
          className='absolute bg-grey-light p-6 mb-4 w-full max-w-2xl max-w-[90%] shadow-xl'
          style={{
            left: '50%',
            bottom: '100%',
            transform: 'translateX(-50%)',
          }}
        >
          <h5 className='mb-2'>Contact Us</h5>
          <p className='leading-6 popup-text'>
            In today’s world of bots and spam, we’ve decided the easiest way to
            contact us is to simply send an email to{' '}
            <strong>sales(at)semplicityinc(dot)​com</strong>. We will get back
            to you within one business day. <br />
            <br />
            By the way, we hate spam. Contacting us will not subscribe you to
            anything. We will never share or sell your contact information to
            any company.
          </p>
        </div>
      )}
      <div onClick={() => setShowPopup((prev) => !prev)}>
        {text ? (
          <span className='text-red text-lg cursor-pointer font-semibold'>
            {text ? text : 'Learn more'}
          </span>
        ) : (
          <button className={fullButtonClassName}>Contact</button>
        )}
      </div>
    </div>
  );
};

export default ContactButton;
