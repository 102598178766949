import React, { useState } from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'
import Burger from './Burger'

const Navbar = ({ pathname }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <header
        className={
          pathname == '/' ? 'header bg-transparent' : 'header bg-gradient'
        }>
        <nav className='navigation'>
          <div className='container'>
            <div className='navigation__inner '>
              <Link className='navigation__inner--logo' to='/'>
                <ReactSVG src='/img/svg/logo.svg' />
                <div className='sr-only'>SEMplicity homepage</div>
              </Link>

              <div
                className={
                  'navigation__list' +
                  ' ' +
                  (open ? 'navigation__list--open' : 'navigation__list--closed')
                }>
                <Link
                  to='/'
                  // onClick={() => scrollTo('/#services')}
                  className='navigation__list--link cursor-pointer'>
                  Home
                </Link>

                <Link className='navigation__list--link' to='/about/'>
                  About
                </Link>
                <Link className='navigation__list--link' to='/resources/'>
                  Resources
                </Link>
                <Link className='navigation__list--link whitespace-no-wrap' to='/forConsultants/'>
                  For Consultants
                </Link>
                <Link className='btn btn--red btn-s' to='/contact/'>
                  Contact
                </Link>
              </div>
              <Burger
                // main={true}
                // navBar={navBar}
                setOpen={setOpen}
                open={open}
              />
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Navbar
