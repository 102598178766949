import React from 'react'

const Burger = ({ open, setOpen, main }) => {
  return (
    <div
      className={open ? 'burger burger-open' : 'burger burger-closed'}
      open={open}
      onClick={() => setOpen(!open)}>
      <span />
      <span />
      <span />
    </div>
  )
}

export default Burger
