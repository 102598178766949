import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import ReactSVG from 'react-svg'

import ContactButton from '../components/ContactButton'

const Footer = ({ pathname }) => {
  const {
    markdownRemark: { frontmatter: data },
  } = useStaticQuery(graphql`
    query FooterQuery {
      markdownRemark(frontmatter: { type: { eq: "settings" } }) {
        frontmatter {
          footer
        }
      }
    }
  `)
  return (
    <>
      <footer className="footer footer-gradient">
        {pathname !== '/about/' && pathname !== '/contact/' ? (
          <section className="offerings">
            <div className="container overflow-x-visible">
              <div className="flex flex-col justify-center items-center mb-10">
                <h3 className="text-center text-white mt-20 max-w-2xl mb-10">
                  {data.footer}
                </h3>
                <ContactButton to="/contact/" />{' '}
              </div>
            </div>
          </section>
        ) : null}
        <div className="container overflow-x-auto">
          <div className="footer__inner ">
            <Link className="footer__inner--logo" to="/">
              <ReactSVG src="/img/svg/logo.svg" />
              <div className="sr-only">SEMplicity homepage</div>
            </Link>

            <div className="footer__list">
              <Link to="/" className="footer__list--link cursor-pointer">
                Home
              </Link>

              <Link className="footer__list--link" to="/about/">
                About
              </Link>
              <Link className="footer__list--link" to="/resources/">
                Resources
              </Link>
              <Link className="footer__list--link" to="/forConsultants/">
                For Consultants
              </Link>
              <Link className="footer__list--link" to="/contact/">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
